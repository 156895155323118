import { createRouter, createWebHistory } from 'vue-router';
import PageHome from '../views/PageHome.vue';

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: PageHome
  },
  {
    path: '/el-plan',
    name: 'Plan',
    component: () => import(/* webpackChunkName: "plan" */ '../views/PagePlan.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // siempre scroll al inicio para todas las rutas
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({ top: 0 })
          }, 300)
        })
      }
    })

export default router
