<template>
    <div class="section newsletter load-element">
        <div class="section-content mt-60 mb-60">
            <h2 class="newsletter-title">{{ newsletterTitle }}</h2>
            <p class="newsletter-text">{{ newsletterMessage }}</p>
            <!--Mailerlite-->
            <div id="mlb2-12550707" class="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-12550707">
                <div class="ml-form-align-center ">
                    <div class="ml-form-embedWrapper embedForm">
                        <div class="ml-form-embedBody ml-form-embedBodyDefault row-form">
                            <div class="ml-form-embedContent" style="margin-bottom: 0px; ">
                            </div>
                            <form class="ml-block-form" action="https://assets.mailerlite.com/jsonp/807070/forms/113740185175327960/subscribe" data-code="" @submit="submitForm" method="post" target="_blank">
                                <div class="ml-form-formContent">
                                    <div class="ml-form-fieldRow ml-last-item">
                                        <div class="ml-field-group ml-field-email ml-validate-email ml-validate-required">
                                            <input aria-label="email" aria-required="true" type="email" class="form-control" data-inputmask="" name="fields[email]" placeholder="Tu email aquí" autocomplete="email">
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" name="ml-submit" value="1">
                                <div class="ml-form-embedSubmit">
                                    <button type="submit" class="button-primary">Suscribirme</button>
                                    <button disabled="disabled" style="display: none;" type="button" class="loading">
                                        <div class="ml-form-embedSubmitLoad"></div>
                                        <span class="sr-only">
                                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                        </span>
                                    </button>
                                </div>
                                <input type="hidden" name="anticsrf" value="true">
                            </form>
                            <!-- Después del formulario -->
                            <div v-if="showMessage" :class="{'success-message': messageType === 'success', 'error-message': messageType === 'error'}">
                                <p>{{ messageContent }}</p>
                            </div>
                        </div>
                        <div class="ml-form-successBody row-success" style="display: none">
                            <div class="ml-form-successContent">
                                <h4>Thank you!</h4>
                                <p>You have successfully joined our subscriber list.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--/Mailerlite-->
            <p class="newsletter-footer">{{ newsletterFooter }}</p>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'NewsletterForm',
    props: {
        newsletterTitle: String,
        newsletterMessage: String,
        newsletterFooter: String
    },
    data() {
        return {
            isLoading: false,
            showMessage: false,
            messageContent: '',
            messageType: 'success' // 'success' o 'error'
        };
    },
    mounted() {
    fetch("https://assets.mailerlite.com/jsonp/807070/forms/113740185175327960/takel")
      .then(response => response.json())
      .then(data => {
        this.handleData(data);
      })
      .catch(error => console.error('Error:', error));
    },
    methods: {
        handleData(data) {
        // Handle your data here
        console.log(data);
        },
        submitForm(e) {
            e.preventDefault(); // Previene la navegación predeterminada del formulario
            this.isLoading = true;
            const formData = new FormData(e.target); // Obtiene los datos del formulario

            fetch("https://assets.mailerlite.com/jsonp/807070/forms/113740185175327960/subscribe", {
                method: 'POST',
                body: formData,
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                this.isLoading = false;
                if(data.success) {
                    this.showMessage = true;
                    this.messageType = 'success';
                    this.messageContent = '¡Gracias por suscribirte!';
                } else {
                    this.showMessage = true;
                    this.messageType = 'error';
                    this.messageContent = 'Hubo un problema con la suscripción. Por favor, intenta de nuevo más tarde.';
                }
            })
            .catch(error => {
                this.isLoading = false;
                this.showMessage = true;
                this.messageType = 'error';
                this.messageContent = 'Error al enviar el formulario: ' + error.message;
            });
        }
    }
}
</script>

