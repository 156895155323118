<template>
    <div @mouseover="showHoverLink = true" @mouseleave="showHoverLink = false" class="hover-link-wrapper">
    <a :href="url" target="_blank">{{ hoverLinkLabel }}</a>
    <div v-if="showHoverLink" class="hover-link">
        <img :src="hoverLinkThumbnail" alt="Thumbnail">
    </div>
    </div>
</template>

<script>
export default {
    name: 'HoverLink',
    props: {
    url: String,
    hoverLinkLabel: String,
    hoverLinkThumbnail: String
    },
    data() {
    return {
        showHoverLink: false
    };
    }
};
</script>
