<template>
  <MainHeader />
  <router-view v-slot="{Component, route}">
    <transition name="slide" mode="out-in">
      <div :key="route.name">  
        <component :is="Component"></component>
      </div>
    </transition>
  </router-view>
  <NewsletterForm newsletterTitle="¿Quieres mantenerte al tanto?" newsletterMessage="Ingresa tu email para recibir actualizaciones sobre Amasy." newsletterFooter="Nunca te enviaremos publicidad o contenido que no cumpla con informar sobre el avance del proyecto."/>
  <MainFooter footerCopy="Amasy ® 2024. Todos los derechos reservados."/>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import NewsletterForm from '@/components/NewsletterForm.vue'
import MainFooter from '@/components/MainFooter.vue'

export default {
  name: 'App',
  components: {
    MainHeader,
    NewsletterForm,
    MainFooter
  },
  mounted () {
    document.title = 'Amasy'
  }
}
</script>
