<template>
    <div class="section amasy-promise">
        <div class="section-content mt-60">
            <h2 class="ornament left">{{ promiseTitle }}</h2>
            <p>{{ promiseMessage }}</p>
            <div class="promise-tabs desktop-only">
                <div class="tabs-container">
                    <div class="tabs">
                        <div 
                            v-for="(tab, index) in tabs" 
                            :key="index" 
                            class="tab" 
                            :class="{ active: activeTab === index }" 
                            @click="activeTab = index"
                        >
                            {{ tab.title }}
                        </div>
                    </div>
                    <div class="tabs-content-wrapper">
                        <div class="tabs-content-inner" :key="activeTab">
                            <div class="desktop-tab-content" v-show="activeTab === 0" key="tab1">
                                <slot name="promiseContent1"></slot>
                            </div>
                            <div class="desktop-tab-content" v-show="activeTab === 1" key="tab2">
                                <slot name="promiseContent2"></slot>
                            </div>
                            <div class="desktop-tab-content" v-show="activeTab === 2" key="tab3">
                                <slot name="promiseContent3"></slot>
                            </div>
                            <div class="desktop-tab-content" v-show="activeTab === 3" key="tab4">
                                <slot name="promiseContent4"></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'AmasyPromise',
    props: {
        promiseTitle: String,
        promiseMessage: String
    },
    data() {
        return {
            activeTab: 0, // Tab activo inicial
            tabs: [
                { title: 'Para artistas y creadorxs' },
                { title: 'Para audiencias apasionadas' },
                { title: 'Para comunidades y nichos' },
                { title: 'Para colaboradorxs y proveedorxs' }
            ]
        };
    }
}

</script>
