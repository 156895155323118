<template>
    <div class="main-section">
        <div class="hero">
            <div class="hero-content">
                <h1>{{ heroTitle }}</h1>
                <p>{{ heroMessage }}</p>
                <button @click="goToAmasyPromise" class="button-primary">{{ heroCta }}</button>
            </div>
        </div>
        <div id="amasyPromise"></div>
    </div>
    
</template>

<script>
// @ is an alias to /src
export default {
    name: 'MainHero',
    props: {
        heroTitle: String,
        heroMessage: String,
        heroCta: String
  },
  methods: {
    goToAmasyPromise() {
      const seccion = document.querySelector('#amasyPromise');
      if (seccion) {
        seccion.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
</script>
