<template>
  <MainHero heroTitle="Creamos" heroMessage="Las soluciones que revolucionarán el arte y la cultura de Chile" heroCta="Conocer más" />
  <AmasyPromise promiseTitle="Nuestra propuesta" promiseMessage="Nuevos sistemas y herramientas digitales para la prosperidad cultural">
    <template v-slot:promiseContent1>
      <p>Una plataforma digital para promover tus creaciones de manera holística y humana, facilitándote el descubrir a lxs colaboradorxs adecuadxs y asegurando transacciones transparentes que nutrirán el desarrollo de tu carrera.</p>
      <img src="../assets/img/promise-1.png" 
      srcset="../assets/img/promise-1.png 1x, ../assets/img/promise-1@2x.png 2x"
      alt="Ilustración">
    </template>
    <template v-slot:promiseContent2>
      <p>Los tickets nunca han sido simples boletas, son símbolos que nos recuerdan la emoción de experiencias vividas. En Amasy las transacciones entre audiencias y creadorxs son una forma de reinversión cultural que se protege y respeta.</p>
      <img src="../assets/img/promise-2.png" 
      srcset="../assets/img/promise-2.png 1x, ../assets/img/promise-2@2x.png 2x"
      alt="Ilustración">
    </template>
    <template v-slot:promiseContent3>
      <p>Espacios para conexiones auténticas, protegidos de la discriminación y comprometidos con celebrar las pasiones que nos congregan. Queremos que la apreciación del arte y la cultura fomente mejores estándares de calidad para todxs.</p>
      <img src="../assets/img/promise-3.png" 
      srcset="../assets/img/promise-3.png 1x, ../assets/img/promise-3@2x.png 2x"
      alt="Ilustración">
    </template>
    <template v-slot:promiseContent4>
      <p>Ayudamos a visibilizar a lxs aliadxs del arte y la cultura que desean entregar mejores estándares de calidad. Un registro histórico de la participación y créditos de lxs que ayudan a transformar visiones creativas en experiencias reales.</p>
      <img src="../assets/img/promise-4.png" 
      srcset="../assets/img/promise-4.png 1x, ../assets/img/promise-4@2x.png 2x"
      alt="Ilustración">
    </template>
  </AmasyPromise>
  <AmasyPromiseMobile promiseTitle="Nuestra propuesta" promiseMessage="Sistemas y herramientas con un compromiso absoluto hacia el arte y la cultura de Chile">
    <template v-slot:promiseContent1>
      <h3>Para artistas y creadorxs</h3>
      <p>Una plataforma digital para promover tus creaciones de manera holística y humana, facilitándote el descubrir a lxs colaboradorxs adecuadxs y asegurando transacciones transparentes que nutrirán el desarrollo de tu carrera.</p>
      <img src="../assets/img/promise-1.png" 
      srcset="../assets/img/promise-1.png 1x, ../assets/img/promise-1@2x.png 2x"
      alt="Ilustración">
    </template>
    <template v-slot:promiseContent2>
      <h3>Para audiencias apasionadas</h3>
      <p>Los tickets nunca han sido simples boletas, son símbolos que nos recuerdan la emoción de experiencias vividas. En Amasy las transacciones entre audiencias y creadorxs son una forma de reinversión cultural que se protege y respeta.</p>
      <img src="../assets/img/promise-2.png" 
      srcset="../assets/img/promise-2.png 1x, ../assets/img/promise-2@2x.png 2x"
      alt="Ilustración">
    </template>
    <template v-slot:promiseContent3>
      <h3>Para comunidades y nichos</h3>
      <p>Espacios para conexiones auténticas, protegidos de la discriminación y comprometidos con celebrar las pasiones que nos congregan. Queremos que la apreciación del arte y la cultura fomente mejores estándares de calidad para todxs.</p>
      <img src="../assets/img/promise-3.png" 
      srcset="../assets/img/promise-3.png 1x, ../assets/img/promise-3@2x.png 2x"
      alt="Ilustración">
    </template>
    <template v-slot:promiseContent4>
      <h3>Para colaboradoxs y proveedorxs</h3>
      <p>Ayudamos a visibilizar a lxs aliadxs del arte y la cultura que desean entregar mejores estándares de calidad. Un registro histórico de la participación y créditos de lxs que ayudan a transformar visiones creativas en experiencias reales.</p>
      <img src="../assets/img/promise-4.png" 
      srcset="../assets/img/promise-4.png 1x, ../assets/img/promise-4@2x.png 2x"
      alt="Ilustración">
    </template>
  </AmasyPromiseMobile>
  <AmasyGoal goalTitle="Nuestro propósito" />
</template>

<script>
import MainHero from '@/components/MainHero.vue';
import AmasyPromise from '@/components/AmasyPromise.vue';
import AmasyPromiseMobile from '@/components/AmasyPromiseMobile.vue';
import AmasyGoal from '@/components/AmasyGoal.vue';

export default {
  name: 'PageHome',
  components: {
    MainHero,
    AmasyPromise,
    AmasyPromiseMobile,
    AmasyGoal
  },
  mounted () {
      document.title = 'Amasy - Inicio'
  }
}
</script>
