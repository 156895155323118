<template>
    <div class="section amasy-goal">
        <div class="section-content mt-60 mb-60">
            <h2 class="ornament left">{{ goalTitle }}</h2>
            <div class="amasy-grid">
                <div class="grid-item goal odd">
                    <div class="goal-image">
                        <img src="../assets/img/goal-1.png" 
                             srcset="../assets/img/goal-1.png 1x, ../assets/img/goal-1@2x.png 2x"
                             alt="Ilustración">
                    </div>
                    <div class="goal-card card-stroke">
                        <div class="goal-card-content card-content">
                            <h3>Prosperidad</h3>
                            <p>Que el trabajo artístico-cultural no signifique un sacrificio. Que la pasión y talento de la comunidad se traduzca a una vida próspera.</p>
                        </div>
                    </div>
                </div>
                <div class="grid-item goal even">
                    <div class="goal-image">
                        <img src="../assets/img/goal-2.png" 
                             srcset="../assets/img/goal-2.png 1x, ../assets/img/goal-2@2x.png 2x"
                             alt="Ilustración">
                    </div>
                    <div class="goal-card card-stroke">
                        <div class="goal-card-content card-content">
                            <h3>Identidad</h3>
                            <p>Que nuestra cultura sea preservada y celebrada, nutriendo un legado en donde el éxito mejore la calidad de vida de las generaciones por venir.</p>
                        </div>
                    </div>
                </div>
                <div class="grid-item goal odd">
                    <div class="goal-image">
                        <img src="../assets/img/goal-3.png" 
                             srcset="../assets/img/goal-3.png 1x, ../assets/img/goal-3@2x.png 2x"
                             alt="Ilustración">
                    </div>
                    <div class="goal-card card-stroke">
                        <div class="goal-card-content card-content">
                            <h3>Desarrollo</h3>
                            <p>Que el mercado de arte y cultura de nuestro país cuente con una infraestructura resiliente y con mejores estándares de calidad.</p>
                        </div>
                    </div>
                </div>
                <div class="grid-item goal even">
                    <div class="goal-image">
                        <img src="../assets/img/goal-4.png" 
                             srcset="../assets/img/goal-4.png 1x, ../assets/img/goal-4@2x.png 2x"
                             alt="Ilustración">
                    </div>
                    <div class="goal-card card-stroke">
                        <div class="goal-card-content card-content">
                            <h3>Transparencia</h3>
                            <p>Visibilizar el contexto del arte y la cultura, para superar los desafíos de forma colectiva y responsable.</p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'AmasyGoal',
    props: {
        goalTitle: String
  }
}
</script>
