<template>
    <header class="header">
        <div class="logo">
            <router-link to="/">
                <img src="../assets/img/amasy.svg" alt="Amasy" class="main-logo">
            </router-link>
        </div>
        <div class="mobile-nav" @click="toggleMenu">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <nav class="desktop-nav" :class="{ 'is-open': isMenuOpen }">
            <div class="desktop-nav-links">
                <router-link to="/" class="nav-link" @click="closeMenu">Inicio</router-link>
                <router-link to="/el-plan" class="nav-link" @click="closeMenu">El Plan</router-link>
            </div>
            <div class="desktop-nav-buttons">
                <button class="close-mobile-nav" @click="toggleMenu">&#x2715;</button>
                <a @click="showModal = true"  class="nav-button-secondary">Iniciar sesión</a>
                <a @click="showModal = true" class="nav-button-primary">Registrarme</a>
            <ModalComponent :isVisible="showModal" @update:isVisible="showModal = $event">
                <h2 class="modal-title">En desarrollo</h2>
                <p class="modal-text">Todavía estamos trabajando en el sistema de cuentas de Amasy.</p>
                <p class="modal-text">Para mantenerte al tanto del avance de Amasy, puedes registrar tu email en el formulario al final de esta página.</p>
            </ModalComponent>
            </div>
        </nav>
    </header>
</template>

<script>
// @ is an alias to /src
import ModalComponent from './ModalComponent.vue';

export default {
    name: 'MainHeader',
    components: {
        ModalComponent
    },
    data() {
        return {
        showModal: false,
        isMenuOpen: false,
        }
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
            if (this.isMenuOpen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
        closeMenu() {
            this.isMenuOpen = false;
            document.body.style.overflow = '';
        }
    }
}
</script>
