<template>
    <div class="section amasy-promise-mobile promise-tabs mobile-only">
        <div class="tabs-container" ref="mobileTabsContainer">
            <div class="mobile-tabs">
                <div 
                    v-for="(tab, index) in tabs" 
                    :key="index" 
                    class="tab" 
                    :class="{ active: activeMobileTab === index }"
                    @click="scrollToContent(index)"
                >
                    {{ tab.title }}
                </div>
            </div>
            <div class="tabs-content-wrapper">
                <div v-for="(content, index) in tabs" :key="'content-' + index" class="mobile-tab-content">
                    <slot :name="'promiseContent' + (index + 1)"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
function debounce(func, wait, immediate) {
    let timeout;
    return function() {
        const context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        }, wait);
        if (immediate && !timeout) func.apply(context, args);
    };
}

export default {
    name: 'AmasyPromise',
    props: {
        promiseTitle: String,
        promiseMessage: String
    },
    data() {
        return {
            activeMobileTab: 0,
            tabs: [
                { title: 'Artistas y creadorxs' },
                { title: 'Audiencias apasionadas' },
                { title: 'Comunidades y nichos' },
                { title: 'Colaboradorxs y proveedorxs' }
            ]
        };
    },
    mounted() {
    this.debouncedScroll = debounce(this.handleScroll, 10); // Adjust debounce time as needed
    window.addEventListener('scroll', this.debouncedScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.debouncedScroll);
    },
    methods: {
        handleScroll() {
            const sections = document.querySelectorAll('.mobile-tab-content');
            const scrollPosition = window.scrollY + window.innerHeight / 2; // Centro del viewport

            let closestSectionIndex = 0;
            let smallestDifference = Infinity;

            sections.forEach((section, index) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;
                const sectionMidPoint = sectionTop + sectionHeight / 1.5; // Punto medio del contenido
                const difference = Math.abs(scrollPosition - sectionMidPoint); // Diferencia entre el centro del viewport y el punto medio del contenido

                if (difference < smallestDifference) {
                    smallestDifference = difference;
                    closestSectionIndex = index;
                }
            });

            this.activeMobileTab = closestSectionIndex;
        },
        scrollToContent(index) {
        const sections = document.querySelectorAll('.mobile-tab-content');
            const navbarHeight = 180; // Altura de tu navbar

            if (sections[index]) {
                const top = sections[index].offsetTop - navbarHeight; // Resta la altura del navbar para crear espacio
                window.scrollTo({ top: top, behavior: 'smooth' });
            }
        }
    }
}
</script>
