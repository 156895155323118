<template>
    <div class="section footer load-element">
        <div class="section-content ornament top">
            <p class="text-link" @click="showModal = true">Créditos y recursos</p>
            <p>{{ footerCopy }}</p>
        </div>
        <teleport to="body">
        <ModalComponent :isVisible="showModal" @update:isVisible="showModal = $event">
            <h2 class="modal-title">Créditos y recursos</h2>
            <p class="modal-text">Información transparente sobre algunos recursos que utilizamos y sus créditos correspondientes.</p>
            <p class="modal-text">Ilustraciones por 
            <HoverLink url="https://www.openpeeps.com" hoverLinkLabel="Open Peeps" :hoverLinkThumbnail="hoverLinkThumbnail.ThumbnailOpenpeeps"></HoverLink>, 
            <HoverLink url="https://www.transhumans.xyz" hoverLinkLabel="Transhumans" :hoverLinkThumbnail="hoverLinkThumbnail.ThumbnailTranshumans"></HoverLink>, 
            <HoverLink url="https://www.streamlinehq.com/illustrations/milano" hoverLinkLabel="Milano" :hoverLinkThumbnail="hoverLinkThumbnail.ThumbnailMilano"></HoverLink>, 
            <HoverLink url="https://www.streamlinehq.com/illustrations/bangalore" hoverLinkLabel="Bangalore" :hoverLinkThumbnail="hoverLinkThumbnail.ThumbnailBangalore"></HoverLink>, 
            <HoverLink url="https://www.absurd.design" hoverLinkLabel="Absurd.design" :hoverLinkThumbnail="hoverLinkThumbnail.ThumbnailAbsurddesign"></HoverLink></p>
        </ModalComponent>
        </teleport>
    </div>
</template>

<script>
import ModalComponent from './ModalComponent.vue';
import HoverLink from './HoverLink.vue';
import ThumbnailOpenpeeps from '../assets/img/th-openpeeps.png';
import ThumbnailTranshumans from '../assets/img/th-transhumans.png';
import ThumbnailMilano from '../assets/img/th-milano.png';
import ThumbnailBangalore from '../assets/img/th-bangalore.png';
import ThumbnailAbsurddesign from '../assets/img/th-absurddesign.png';

// @ is an alias to /src
export default {
    name: 'MainFooter',
    components: {
        ModalComponent,
        HoverLink
    },
    data() {
        return {
        showModal: false,
        hoverLinkThumbnail: {
            ThumbnailOpenpeeps,
            ThumbnailTranshumans,
            ThumbnailMilano,
            ThumbnailBangalore,
            ThumbnailAbsurddesign
            }
        }
    },
    props: {
        footerCopy: String
    }
}
</script>
