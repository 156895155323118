<template>
    <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <slot></slot>
        <button @click="closeModal">&#x2715;</button>
      </div>
    </div>
</template>

<script>
export default {
    name: 'ModalComponent',
    props: {
    isVisible: Boolean,
    },
    emits: ['update:isVisible'],
    methods: {
        closeModal() {
            this.$emit('update:isVisible', false);
        }
    },
    watch: {
        isVisible(newValue) {
            if (newValue) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        }
    }
}
</script>